<template>
  <div>
     <!-- 编辑弹出 -->
    <van-popup :value="show" @click-overlay="toCancel"   >
        <div class="field-wrapper " >
           <div class="top ">请输入{{title}}</div>
          
            <div class="cen">
                  <van-field size="large"  class="cen-field" v-model="newValue" :placeholder="'请输入'+title" />
            </div>
          
        
           <div class="button-wrap mt5">
               <van-button type="default" class="mr5" @click.stop="toCancel" >取消</van-button>
               <van-button type="info"  @click.stop="toConfirm" >确认</van-button>
           </div>
       </div>
    </van-popup>
  </div>
</template>

<script>

import { Popup, Field, Button } from 'vant';
export default {
    name:'MyDialogField',
    components:{
         [Popup.name]:Popup,
         [Field.name]:Field,
         [Button.name]:Button
    },
    props:{
         title:{
             type:String,
             default:'信息'
         },
         value:{
             type:[String,Number],
             default:''
         },
         show:{
             type:Boolean,
             default:false
         }
    },
    data(){
        return{
            newValue:''
        }
    },
    methods:{
          //确认修改
          toConfirm(){    
              let v = this.newValue;
              this.newValue = '';
             this.$emit('confirm',v);
             this.toCancel();
     
          },
          toCancel(){
             this.newValue = '';
             this.$emit('cancel',false);
          }
    },
    watch:{
        value(e){

            this.newValue = e;
        }
        
    }

}
</script>

<style >
.field-wrapper{
    width: 80vw;
}
.field-wrapper .top{
        justify-content: center;
		font-size: 16px;
		padding: 12px 20px 16px;
		border-bottom:none !important;
         margin-bottom:0 !important;
}
.field-wrapper .cen{
	padding: 5px 10px;
	font-size: 16px;
	border: 1px solid #dedede;
    margin: 10px 10px;
}
.field-wrapper .cen .cen-field{
	padding:  0 !important;
}
.field-wrapper .button-wrap{
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}
.field-wrapper .button-wrap button{
	width: 100px;
	margin: 10px;
    margin: 10px;
    height: 35px;
    line-height: 35px;
}
</style>