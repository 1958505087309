<template>
    <div>

        <van-cell-group>
            <van-cell center title="头像">
                <div slot="default">
                    <van-image fit='cover' width="1.1rem" height="1.1rem" :src="userInfo.avatar"></van-image>
                </div>
            </van-cell>
            <van-cell title="昵称" is-link :value="userInfo.nickname" @click="nickshow=true"/>
            <van-cell title="性别" is-link :value="columns[userInfo.gender]" @click="sexshow=true"/>
			<van-field v-model="userInfo.mobile" autosize type="input" label="手机号码:" placeholder="请填写手机号码" class="mobile"/>
            <van-cell title="出生月份" is-link :value="userInfo.birthday" @click="bornshow=true"/>
            <van-field v-model="userInfo.address" rows="3" autosize label="详细地址" type="textarea" maxlength="30"
                       placeholder="空空如也" show-word-limit/>
        </van-cell-group>


        <van-button class="save" type="info" block @click="save">保存</van-button>

        <!-- 昵称 -->
        <MyDialogField :show="nickshow" title="昵称" :value="userInfo.nickname" @confirm="toConfirm"
                       @cancel="toCancel"></MyDialogField>
        <!-- 性别 -->
        <van-popup v-model="sexshow" position="bottom">
            <van-picker  show-toolbar :columns="columns" @confirm="onSexConfirm" @cancel="sexshow = false"/>
        </van-popup>
        <!-- 出生月份 -->
        <van-popup v-model="bornshow" position="bottom">
            <van-datetime-picker  type="date" :min-date="minDate" :max-date="maxDate"
                                 @confirm="onBornConfirm" @cancel="bornshow = false"/>
        </van-popup>


    </div>
</template>
<script>
    import {
        Field,
        Cell,
        CellGroup,
        Uploader,
        Button,
        Popup,
        Picker,
        DatetimePicker
    } from "vant";

    import MyDialogField from "@/components/mydialogfield";
    import {user_update} from '@/request/api';

    export default {
        components: {
            [Field.name]: Field,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Button.name]: Button,
            [Popup.name]: Popup,
            [Picker.name]: Picker,
            [DatetimePicker.name]: DatetimePicker,
            MyDialogField
        },
        data() {
            return {
                name: '',
                columns: ['男', '女', '保密'],
                nickshow: false,
                sexshow: false,
                bornshow: false,
                minDate: new Date(1970, 1, 1),
                maxDate: new Date(2099, 12, 1),
                currentDate: new Date()
            };
        },
        computed:{
            userInfo(){
                return this.$store.state.userInfo;
            }
        },
        mounted: function () {

        },
        methods: {
            dateFormat: function(time) {
                var date = new Date(time);
                var year = date.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                 * */
                var month =
                    date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1;
                var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                // 拼接
                return year + "-" + month + "-" + day;
            },
            toConfirm(e) {
                this.userInfo.nickname = e;
            },
            toCancel(e) {
                this.nickshow = e;
            },
            save() {
                if(this.userInfo.nickname==''){
                    this.$toast.fail("昵称不能为空");
					return;
                }
                if(this.userInfo.gender===''){
                    this.$toast.fail("请选择性别");
					return;
                }
                if(this.userInfo.birthday==''){
                    this.$toast.fail("请选择你的出生日期");
					return;
                }
                let param = {
					nickname:this.userInfo.nickname,
					gender:this.userInfo.gender,
					birthday:this.userInfo.birthday,
					address:this.userInfo.address,
					mobile: this.userInfo.mobile
				}
                user_update(param).then(res => {
                    if(res.code==1){
                        this.$toast.success("修改成功");
                        //更新用户信息
                        this.$store.dispatch("initUserInfo");
                        this.$router.go(-1);
                    }else{
                        this.$toast.fail(res.msg);
                    }
                }).catch(err => {
                    this.$toast.fail("修改失败");
                })
            },
            checkData: function () {

            },
            //确定性别
            onSexConfirm(e,index) {
                this.sexshow = false;
                this.userInfo.gender = index;
            },
            //出生日期 确定
            onBornConfirm(value) {
                this.userInfo.birthday = this.dateFormat(value);
                this.bornshow = false;
            }
        }
    };
</script>
<style lang="less">

    .save {
        width: 90%;
        margin: 0 auto;
        margin-top: 60px;
    }
	
	.mobile input{
		text-align: right;
	}
</style>